import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';
import { useLoader } from '../../Reducers/LoaderProvider';
import { useDispatch } from 'react-redux';
import { DeleteAccountcustomer } from '../../API/subscription';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function DeleteAccount() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const user = useSelector((state) => state.Auth);
  const { setLoading } = useLoader();
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])
  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = () => {
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    setLoading(true)
    const active = 0;
    dispatch(DeleteAccountcustomer(active,navigate))
    setLoading(false)

    setOpenDialog(false);
    // Handle actual deletion logic here
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{
      padding: { xs: "0px 0px", sm: "0px 0px", md: "20px  80px", lg: "20px  80px" },
      backgroundColor: "#F1F1F1",
    }}>
      <Box
        sx={{
          padding: { xs: "20px", md: "40px" },
          borderRadius: "10px",
          backgroundColor: "#ffffff",
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: 600, color: "#000" }}>
            Delete My Account
          </Typography>

          <Box sx={{ mb: 1 }}>
            <Typography
              sx={{
                color: 'error.main',
                fontWeight: 500,
                fontSize: '0.875rem',
                mb: 0.5,
              }}
            >
              Please note:
            </Typography>
            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              Deleting your account will permanently remove all your data from our system
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 1,
            }}
          >
            <Button
              variant="contained"
              onClick={handleDelete}
              sx={{
                bgcolor: '#C42A25',
                '&:hover': {
                  bgcolor: '#9C1F1A',
                },
                textTransform: 'none',
                px: 3,
              }}
            >
              Delete Account
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCancel}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            maxWidth: '400px',
            width: '100%',
          }
        }}
      >
        <DialogContent sx={{ p: 3 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              mb: 1,
              textAlign: 'center'
            }}
          >
            Are you sure you want to delete your account?
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              color: 'text.secondary',
              mb: 3,
              textAlign: 'center'
            }}
          >
            This action cannot be undone. All your data will be permanently removed from our system
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={handleConfirmDelete}
              sx={{
                bgcolor: '#C42A25',
                color: 'white',
                '&:hover': {
                  bgcolor: '#9C1F1A',
                },
                px: 4,
                py: 1,
                borderRadius: '6px',
                textTransform: 'none',
              }}
            >
              Done
            </Button>
            <Button
              onClick={handleCancel}
              sx={{
                bgcolor: '#F5F5F5',
                color: '#000',
                '&:hover': {
                  bgcolor: '#EBEBEB',
                },
                px: 4,
                py: 1,
                borderRadius: '6px',
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default DeleteAccount;